import { AcceptInviteProps, InvitationRequest, IntegrationSettings } from "../models";
import { api } from "./api";

// Debug function to log detailed information
const logDebug = (message: string, data?: any) => {
  console.log(`[Integrations Debug] ${message}`, data || '');
};

const integrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrations: builder.query({
      query: () => {
        logDebug('Making getIntegrations request');
        return {
          url: `/integrations`,
          method: 'GET',
        };
      },
      // Add transformResponse to log the response data
      transformResponse: (response: any) => {
        logDebug('getIntegrations response:', response);
        return response;
      },
      // Add transformErrorResponse to log errors
      transformErrorResponse: (response: any) => {
        logDebug('getIntegrations error:', response);
        return response;
      },
      // Add onQueryStarted for additional debugging
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        logDebug('getIntegrations query started');
        try {
          const { data } = await queryFulfilled;
          logDebug('getIntegrations query fulfilled with data:', data);
        } catch (error) {
          logDebug('getIntegrations query failed:', error);
        }
      },
    }),
    checkExistingAcsToken: builder.query({
      query: () => `/integrations/destination-exists`,
    }),
    getLeadsData: builder.query({
      query: (integrationId) => `/integrations/${integrationId}/leads`,
    }),
    getIntegrationFields: builder.query({
      query: (integrationId) => `/integrations/${integrationId}/fields`,
    }),
    createIntegration: builder.mutation({
      query: (data) => ({
        url: `/integrations`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    createSourceIntegration: builder.mutation({
      query: (data) => ({
        url: `/integrations/${data.integration_id}/sources`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    createDestinationIntegration: builder.mutation({
      query: (data) => ({
        url: `/integrations/${data.integration_id}/destinations`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    testMapping: builder.mutation({
      query: (data) => ({
        url: `/integrations/${data.integration_id}/test-mappings`,
        method: "POST",
        body: data.payload,
      }),
    }),
    syncIntegration: builder.mutation({
      query: (integrationId) => ({
        url: `/integrations/${integrationId}/leads`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    pushLead: builder.mutation({
      query: (data) => ({
        url: `/integrations/${data.integration_id}/leads/${data.lead_id}/sync`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    deleteIntegration: builder.mutation({
      query: (integrationId) => ({
        url: `/integrations/${integrationId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    toggleLiveIntegration: builder.mutation({
      query: (integrationId) => ({
        url: `/integrations/${integrationId}/live`,
        method: "PATCH",
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    updateIntegrationSettings: builder.mutation({
      query: (data: { integrationId: number, settings: IntegrationSettings }) => ({
        url: `/integrations/${data.integrationId}/settings`,
        method: "PATCH",
        body: data.settings,
      }),
      invalidatesTags: [{ type: 'Integrations' }],
    }),
    downloadLeads: builder.mutation({
      query: (integrationId) => ({
        url: `/integrations/${integrationId}/leads/download`,
        method: "POST",
      }),
    }),
    inviteCollaborator: builder.mutation({
      query: (data: InvitationRequest) => ({
        url: `/integrations/invite`,
        method: "POST",
        body: data,
      }),
    }),
    acceptInvitation: builder.mutation({
      query: (data: AcceptInviteProps) => ({
        url: `/integrations/accept-invitation`,
        method: "POST",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetIntegrationsQuery,
  useGetIntegrationFieldsQuery,
  useCreateIntegrationMutation,
  useGetLeadsDataQuery,
  useCheckExistingAcsTokenQuery,
  useCreateSourceIntegrationMutation,
  useCreateDestinationIntegrationMutation,
  useTestMappingMutation,
  useSyncIntegrationMutation,
  useDeleteIntegrationMutation,
  useToggleLiveIntegrationMutation,
  usePushLeadMutation,
  useDownloadLeadsMutation,
  useInviteCollaboratorMutation,
  useAcceptInvitationMutation,
  useUpdateIntegrationSettingsMutation
} = integrationApi;
