import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux'
import { store } from './state/store'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './types';

// Icons from https://heroicons.com/

const LOCALHOST = 'http://localhost:8000/'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const AppWrapper = process.env.REACT_APP_API_BASE_URL === LOCALHOST
  ? (<React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>)
  : (
    <Provider store={store}>
      <App />
    </Provider>
  );


const Wrapper = () => {
  return (
    <>
     {AppWrapper}
    </>
  );
};
root.render(
  <Wrapper />
);
