// src/components/Auth/AuthCallbackHandler.tsx
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../../utils/supabase/client';
import Loading from '../../../components/loading';
import { Session } from "@supabase/supabase-js";
import { useAuth } from '../../../context/AuthContext';
import { setSession } from '../../../state/users/slice';
import { useDispatch } from 'react-redux';
import { BASE_URL } from '../../../types';

interface UserApiResponse {
  // Define your expected API response type here
  success: boolean;
  data?: any;
  error?: string;
}

// Move this to a separate auth utility file
export const logout = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

async function callUserApi(session: Session): Promise<UserApiResponse> {
  const { user } = session;
  
  if (!user?.email || !user?.id) {
    throw new Error('Missing required user data');
  }

  console.log('Using BASE_URL:', BASE_URL);
  
  try {
    const response = await fetch(`${BASE_URL}/users/auth`, {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: user.email,
        supabase_id: user.id,
        first_name: user.user_metadata.first_name || '',
        last_name: user.user_metadata.last_name || '',
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(
        errorData?.message || 
        `API call failed: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    // Log the error for debugging but throw a user-friendly message
    console.error('User API call failed:', error);
    throw new Error('Failed to complete signup. Please try again.');
  }
}

function AuthCallbackHandler() {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch();
  
    useEffect(() => {
      const handleAuthCallback = async () => {
        try {
          const { data: { session }, error: sessionError } = await supabase.auth.getSession();
          
          if (sessionError) throw sessionError;
  
          if (!session) {
            navigate('/login');
            return;
          }
  
          // Call your backend API
          const response = await callUserApi(session);
          console.log('User API response:', response);
  
          // Update Redux store
          dispatch(setSession({
            user: session.user,
            accessToken: session.access_token
          }));
  
          navigate('/dashboard');
        } catch (err) {
          console.error('Auth error:', err);
          setError(err instanceof Error ? err.message : 'Authentication failed');
          setTimeout(() => navigate('/login'), 3000);
        }
      };
  
      handleAuthCallback();
    }, [navigate, dispatch]);


  if (error) {
    return (
      <div className="flex flex-col gap-4 justify-center items-center h-screen">
        <span className="text-red-500 text-center px-4">{error}</span>
        <button 
          onClick={() => navigate("/login")} 
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Back to Login
        </button>
      </div>
    );
  }
  
  return (
    <div className="flex justify-center items-center h-screen">
      <Loading />
    </div>
  );
}

export default AuthCallbackHandler;
