// Need to use the React-specific entry point to allow generating React hooks
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../types";
import { supabase } from "../utils/supabase/client";

// Debug function to log detailed information
const logDebug = (message: string, data?: any) => {
  console.log(`[API Debug] ${message}`, data || '');
};

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    logDebug('Preparing headers for API request');
    logDebug('Using BASE_URL:', BASE_URL);
    
    try {
      const { data, error } = await supabase.auth.getSession();
      
      if (error) {
        logDebug('Error getting Supabase session:', error);
        return headers;
      }
      
      const session = data.session;
      
      headers.set('Content-Type', 'application/json');
      
      if (session?.access_token) {
        logDebug('Session found, adding Authorization header');
        headers.set('Authorization', `Bearer ${session.access_token}`);
      } else {
        logDebug('No session or access token available');
      }
      
      // Log all headers for debugging
      const headerEntries: Record<string, string> = {};
      headers.forEach((value, key) => {
        headerEntries[key] = value;
      });
      logDebug('Final request headers:', headerEntries);
      
      return headers;
    } catch (e) {
      logDebug('Exception in prepareHeaders:', e);
      return headers;
    }
  },
})

export const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  logDebug('Executing query with args:', args);
  
  try {
    let result = await baseQuery(args, api, extraOptions);
    
    logDebug('Query result:', {
      status: result.meta?.response?.status,
      error: result.error,
      data: result.data ? 'Data received' : 'No data'
    });
    
    if (result.error) {
      logDebug('Query error details:', result.error);
      
      if (result.error.status === 401) {
        logDebug('Unauthorized error, attempting to refresh session');
        
        try {
          // Refresh the session
          const { data, error } = await supabase.auth.getSession();
          
          if (error) {
            logDebug('Error refreshing session:', error);
            return result;
          }
          
          if (data.session) {
            logDebug('Session refreshed, retrying request');
            // Retry the original request
            result = await baseQuery(args, api, extraOptions);
            logDebug('Retry result:', {
              status: result.meta?.response?.status,
              error: result.error,
              data: result.data ? 'Data received' : 'No data'
            });
          } else {
            logDebug('No session after refresh, signing out');
            // If still no session, sign out
            await supabase.auth.signOut();
          }
        } catch (refreshError) {
          logDebug('Exception during session refresh:', refreshError);
        }
      }
    }
    
    return result;
  } catch (e) {
    logDebug('Exception in baseQueryWithReauth:', e);
    return {
      error: {
        status: 'FETCH_ERROR',
        error: String(e)
      }
    };
  }
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  reducerPath: "api",
  refetchOnMountOrArgChange: true,
  tagTypes: ["Integrations", "Mappings", "Audiences", "Leads", "Charts"],
});
