import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetIntegrationsQuery } from "../../services/integrations";
import IntegrationsTable from "../../components/Integrations/Table";
import OxButton from "../../components/Ui/button";
import { useAuth } from "../../context/AuthContext"; // Import useAuth
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { supabase } from "../../utils/supabase/client";

// Debug function to log detailed information
const logDebug = (message: string, data?: any) => {
  console.log(`[Home Debug] ${message}`, data || '');
};

const Home = () => {
    const navigate = useNavigate();
    const { user, loading: authLoading, session } = useAuth();
    const [manualRefreshTrigger, setManualRefreshTrigger] = useState(0);
    const isAuthenticated = useSelector((state: RootState) => state.usersReducer.isAuthenticated);
    
    // Force a manual refresh of the query
    const forceRefresh = () => {
        logDebug('Manually forcing refresh');
        setManualRefreshTrigger(prev => prev + 1);
    };
    
    // Check Supabase session directly
    useEffect(() => {
        const checkSession = async () => {
            try {
                const { data, error } = await supabase.auth.getSession();
                logDebug('Direct Supabase session check:', { 
                    hasSession: !!data.session,
                    error: error?.message
                });
                
                if (data.session) {
                    logDebug('Session details:', {
                        userId: data.session.user.id,
                        expiresAt: new Date(data.session.expires_at! * 1000).toISOString(),
                        hasAccessToken: !!data.session.access_token
                    });
                }
            } catch (e) {
                logDebug('Error checking Supabase session:', e);
            }
        };
        
        checkSession();
    }, []);
    
    const { 
        data, 
        isFetching: isIntegrationFetching, 
        refetch,
        error,
        isLoading,
        isError,
        isSuccess
    } = useGetIntegrationsQuery({}, {
        // Skip the query if not authenticated
        skip: !isAuthenticated,
        // Add polling to retry the request periodically
        pollingInterval: 10000, // Poll every 10 seconds
        // Force refetch when this value changes
        refetchOnMountOrArgChange: true,
    });

    // Log detailed state information
    useEffect(() => {
        logDebug("Auth state:", { 
            user: user?.id, 
            authLoading,
            isAuthenticated,
            hasSession: !!session
        });
        
        logDebug("Integration query state:", { 
            isLoading, 
            isIntegrationFetching,
            isSuccess,
            isError, 
            error: error?.toString(),
            dataReceived: !!data,
            dataLength: data ? data.length : 0
        });
        
        // If authenticated but no request is being made, force a refetch
        if (isAuthenticated && !isLoading && !isIntegrationFetching && !isSuccess && !isError) {
            logDebug("Authenticated but no request made, forcing refetch");
            refetch();
        }
    }, [user, authLoading, isLoading, isIntegrationFetching, isError, error, data, isAuthenticated, session, isSuccess]);
    
    // Trigger refetch when authentication state changes
    useEffect(() => {
        if (isAuthenticated) {
            logDebug("Authentication state changed to authenticated, refetching");
            refetch();
        }
    }, [isAuthenticated, refetch]);

    const handleButtonClick = () => {
        navigate('/integrations/new');
    }

    const handleInviteCollaborator = () => {
        navigate('/integrations/invite');
    }

    // Show loading state
    if (isLoading || isIntegrationFetching) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
                    <div>Loading integrations...</div>
                    <button onClick={() => refetch()} className="text-blue-500 mt-4">
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    // Show error state
    if (isError) {
        return (
            <div className="container mt-4 mx-auto">
                <div>Error loading integrations: {error?.toString()}</div>
                <button onClick={() => refetch()} className="text-blue-500">
                    Try again
                </button>
            </div>
        );
    }

    // If data is empty, show a message
    if (!data || data.length === 0) {
        return (
            <div className="container mt-4 mx-auto">
                <div className="flex justify-between flex-row">
                    <div className="text-3xl">
                        Integrations
                    </div>
                    <div className="flex justify-between">
                        <OxButton value={"Create New Integration"} onClickHandler={handleButtonClick} />
                    </div>
                </div>
                <hr className="h-px my-2 bg-gray-200 border-0" />
                <div className="text-center py-10">
                    <p className="text-gray-500 mb-4">No integrations found. Create your first integration to get started.</p>
                    <button onClick={handleButtonClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Create Integration
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-4 mx-auto">
            <div className="flex justify-between flex-row">
                <div className="text-3xl">
                    Integrations
                </div>
                <div className="flex justify-between">
                    <OxButton value={"Create New Integration"} onClickHandler={handleButtonClick} />
                    {data && data.length > 0 && 
                        <OxButton value={"Invite Collaborator"} onClickHandler={handleInviteCollaborator} />
                    }
                </div>
            </div>
            <hr className="h-px my-2 bg-gray-200 border-0" />
            <IntegrationsTable 
                isIntegrationFetching={isIntegrationFetching} 
                integrations={data} 
                refetchIntegrations={refetch} 
            />
        </div>
    )
}

export default Home;
