// src/context/AuthContext.tsx
import { createContext, useContext, useEffect, useState } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { supabase } from '../utils/supabase/client';
import { useDispatch } from 'react-redux';
import { setSession as setReduxSession, clearSession } from '../state/users/slice';

// Debug function to log detailed information
const logDebug = (message: string, data?: any) => {
  console.log(`[Auth Debug] ${message}`, data || '');
};

interface AuthContextType {
  user: User | null;
  session: Session | null;
  loading: boolean;
  checkSession: () => Promise<void>;
  refreshSession: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  session: null,
  loading: true,
  checkSession: async () => {},
  refreshSession: async () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // Update both local state and Redux store
  const updateSessionState = (currentSession: Session | null) => {
    logDebug('Updating session state:', {
      hasSession: !!currentSession,
      userId: currentSession?.user?.id,
      expiresAt: currentSession?.expires_at 
        ? new Date(currentSession.expires_at * 1000).toISOString()
        : null
    });
    
    if (currentSession) {
      setSession(currentSession);
      setUser(currentSession.user);
      
      // Update Redux store
      dispatch(setReduxSession({
        user: currentSession.user,
        accessToken: currentSession.access_token
      }));
    } else {
      setSession(null);
      setUser(null);
      
      // Clear Redux store
      dispatch(clearSession());
    }
  };

  const checkSession = async () => {
    logDebug('Checking session');
    setLoading(true);
    
    try {
      const { data, error } = await supabase.auth.getSession();
      
      if (error) {
        logDebug('Error getting session:', error instanceof Error ? error.message : error);
        updateSessionState(null);
        return;
      }
      
      const currentSession = data.session;
      logDebug('Session check result:', { 
        hasSession: !!currentSession
      });
      
      updateSessionState(currentSession);
    } catch (error) {
      logDebug('Exception checking session:', error);
      updateSessionState(null);
    } finally {
      setLoading(false);
    }
  };
  
  const refreshSession = async () => {
    logDebug('Manually refreshing session');
    setLoading(true);
    
    try {
      const { data, error } = await supabase.auth.refreshSession();
      
      if (error) {
        logDebug('Error refreshing session:', error instanceof Error ? error.message : error);
        updateSessionState(null);
        return;
      }
      
      const currentSession = data.session;
      logDebug('Session refresh result:', { 
        hasSession: !!currentSession
      });
      
      updateSessionState(currentSession);
    } catch (error) {
      logDebug('Exception refreshing session:', error);
      updateSessionState(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    logDebug('AuthProvider: Initializing');
    checkSession();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, currentSession) => {
        logDebug('Auth state changed:', { 
          event, 
          userId: currentSession?.user?.id,
          hasSession: !!currentSession
        });
        
        updateSessionState(currentSession);
        setLoading(false);
      }
    );

    return () => {
      logDebug('Unsubscribing from auth changes');
      subscription.unsubscribe();
    };
  }, []);
  
  // Periodically refresh the session to ensure it's valid
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (session) {
        logDebug('Periodic session refresh');
        refreshSession();
      }
    }, 5 * 60 * 1000); // Refresh every 5 minutes
    
    return () => clearInterval(refreshInterval);
  }, [session]);

  const value = {
    user,
    session,
    loading,
    checkSession,
    refreshSession,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
