import { useEffect, useState } from 'react';
import "../styles.css"
import banner from '../../../assets/images/banner.png';
import googleLogo from '../../../assets/images/googleLogo.png';
import facebookLogo from "../../../assets/images/facebookLogo.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../../../utils/supabase/client';
import { useAuth } from '../../../context/AuthContext';

const APP_URL = process.env.REACT_APP_APP_URL

const OAuthButtons = () => {
  const signInWithGoogle = async () => {
    const {  error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: { redirectTo: window.location.origin + "/auth/callback" },
    });
    if (error) {
      console.error("Error signing in with Google:", error.message);
    } 
  };

  const signInWithFacebook = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'facebook',
      options: { redirectTo: window.location.origin + "/auth/callback" },
    });
    if (error) {
      console.error("Error signing in with Facebook:", error.message);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <button
        className="flex items-center justify-center text-sm leading-normal font-light border border-[#C2C2C2] p-2 mb-2 rounded-lg hover:bg-gray-50"
        onClick={signInWithGoogle}
      >
        <img src={googleLogo} alt="Google" className="w-6 h-6 mr-2" />
        Sign in with Google
      </button>
      <button
        className="flex items-center justify-center text-sm leading-normal font-light mb-2 border border-[#C2C2C2] p-2 rounded-lg hover:bg-gray-50"
        onClick={signInWithFacebook}
      >
        <img src={facebookLogo} alt="Facebook" className="w-6 h-6 mr-2" />
        Sign in with Facebook
      </button>
    </div>
  );
};



const SignIn = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get('message');
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const handleEmailLogin = async (e: React.FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      setLoginError('');
      
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password
        });
        
        if (error) throw error;
        // Login successful - the auth state listener in AuthContext will handle the session
        // Redirect will happen automatically after the session is detected
      } catch (error: any) {
        console.error('Error signing in:', error.message);
        setLoginError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    return (
        <div className="flex min-h-screen">
        <div className="flex flex-1 justify-center items-center">
          <div className="bg-white p-11 rounded-2xl shadow-[0px_4px_4px_0px_rgba(0,0,0,0.16);] max-w-md w-full">
            <h2 className="text-center text-2xl font-semibold mb-5">Welcome!</h2>
            <OAuthButtons />
            <div className="text-center text-sm leading-normal font-light mb-4">or login using email</div>
            <form className="flex flex-col gap-4" onSubmit={handleEmailLogin}>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="p-3 rounded-lg border border-gray-300 placeholder-[#CCCCCC] placeholder:text-sm"
                required
              />
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="p-3 rounded-lg border border-gray-300 placeholder:text-[#CCCCCC] placeholder:text-sm"
                required
              />
              <a href="#" className="text-sm text-center font-semibold">Don't remember your password?</a>
              <button 
                type="submit" 
                className="bg-ox-secondary-azure text-sm text-white p-3 rounded-lg hover:bg-ox-secondary-charcoal mb-6"
                disabled={isLoading}
              >
                {isLoading ? 'Logging in...' : 'Login'}
              </button>
            </form>
            {loginError && <div className="text-red-500 text-sm text-center">{loginError}</div>}
            {message && <div className="text-red-500 text-sm text-center">{message}</div>}
          </div>
        </div>
        <div className="hidden lg:flex flex-1 bg-cover relative">
          <img
            src={banner}
            alt="Omnifyx Banner"
            className="object-cover absolute inset-0 w-full h-full"
          />
        </div>
      </div>
    );
};

export default SignIn;
