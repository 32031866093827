import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { supabase } from './utils/supabase/client';
import { RootState } from './state/store';
import { setSession, clearSession } from './state/users/slice';

// Components
import SignIn from './pages/Auth/SignIn';
import Home from './pages/Home';
import Loading from './components/loading';
import LeadsPage from './pages/Leads/Index';
import ConnectSource from './pages/Integrations/ConnectSource';
import AddDestination from './pages/Integrations/ConnectDestination';
import NewIntegrationForm from './pages/Integrations/NewIntegration';
import Mappings from './pages/Mappings/Index';
import TestMappingForm from './pages/Mappings/TestMappingsForm';
import AudienceForm from './pages/Audiences/AudienceForm';
import ListAudiences from './pages/Audiences/Audiences';
import ListCharts from './pages/Charts/Charts';
import ChartsForm from './pages/Charts/ChartForm';
import ViewChart from './pages/Charts/ChartView';
import Layout from './components/Layout/AppLayout';
import SignUp from './pages/Auth/Signup';
import AuthCallbackHandler from './pages/Auth/VerifyLogin';
import Mediator from './pages/Audiences/Mediator';
import InviteCollaborator from './pages/Integrations/Invite';
import AcceptInvitation from './pages/Integrations/InviteAccept';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.usersReducer.isAuthenticated);
  const isLoading = useSelector((state: RootState) => state.usersReducer.isLoading);

  useEffect(() => {
    // Check initial session
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (session) {
        dispatch(setSession({
          user: session.user,
          accessToken: session.access_token
        }));
      }
    };
    
    checkSession();

    // Set up auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (session) {
        dispatch(setSession({
          user: session.user,
          accessToken: session.access_token
        }));
      } else {
        dispatch(clearSession());
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    dispatch(clearSession());
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[100vh]">
        <Loading />
      </div>
    );
  }

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/invite/:token" element={<AcceptInvitation />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/auth/callback" element={<AuthCallbackHandler />} />
        <Route path="/login" element={<SignIn />} />
        
        {isAuthenticated ? (
          <Route path="/" element={<Layout logout={handleLogout} />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/integrations">
              <Route path="new" element={<NewIntegrationForm />} />
              <Route path=":id/source" element={<ConnectSource />} />
              <Route path=":id/destination" element={<AddDestination />} />
              <Route path=":id/leads" element={<LeadsPage />} />
              <Route path=":id/mappings" element={<Mappings />} />
              <Route path=":id/mappings/test" element={<TestMappingForm />} />
              <Route path="invite" element={<InviteCollaborator />} />
            </Route>
            <Route path="/audiences">
              <Route index element={<ListAudiences />} />
              <Route path="new" element={<AudienceForm />} />
              <Route path=":id" element={<AudienceForm />} />
              <Route path=":id/mediator" element={<Mediator />} />
            </Route>
            <Route path="/charts">
              <Route index element={<ListCharts />} />
              <Route path="new" element={<ChartsForm />} />
              <Route path=":id/edit" element={<ChartsForm />} />
              <Route path=":id/view" element={<ViewChart />} />
            </Route>
          </Route>
        ) : (
          <Route path="*" element={<SignIn />} />
        )}
      </Routes>
    </Router>
  );
};

export default App;
