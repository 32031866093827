import React from "react";
import OxButton from "../../components/Ui/button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "../../state/store";
import { useCreateIntegrationMutation } from "../../services/integrations";
import {
    setSourceIntegrationValues
} from "../../state/integrations/slice";
import Loading from "../../components/loading";


const NewIntegrationForm = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = React.useState("");
    const dispatch = useAppDispatch();
    const [integrationFormState, setIntegrationFormState] = React.useState({
        name: "",
        source: "",
        destination: ""
    });

    const [createIntegration, { isLoading: createIntegrationLoading }] = useCreateIntegrationMutation()

    const onChangeHandler = (e: any) => {
        setIntegrationFormState({
            ...integrationFormState,
            [e.target.name]: e.target.value
        })
    }
    const onButtonClickHandler = () => {
        createIntegration(integrationFormState)
        .unwrap().then((data) => {
            dispatch(setSourceIntegrationValues({ integration_id: data.id }))
            setErrorMessage("");
            navigate(`/integrations/${data.id}/source`);
        }).catch((err) => {
            console.log(err);
            if (err.status === 409) {
                setErrorMessage("This integration already exists");
            } else {
                setErrorMessage("Something went wrong. Could not add source! Try again later");
            }
        });
    }

    if (createIntegrationLoading) {
        return (
            <div className="flex justify-center items-center h-[100vh]">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full">
            {errorMessage && <div className="text-red-500 text-center">{errorMessage}</div>}
            <h1 className="text-3xl text-center font-bold mt-4">New Integration</h1>
            <div className="flex justify-center">
                <div className="flex justify-between items-center mt-4 w-4/5">
                    <div className="mb-5 w-1/3">
                        <label htmlFor="integrationName" className="block text-lg font-medium text-gray-900  text-lg ">Integration Name</label>
                        <input type="text" name="name" onChange={onChangeHandler} id="integrationName" className="form-control bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Name" required />
                    </div>
                    <div className="mb-5 w-1/3 mx-4">
                        <label htmlFor="sources" className="block text-lg font-medium text-gray-900">Choose Data Source</label>
                        <select id="sources" name="source" onChange={onChangeHandler} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value=""></option>
                            <option value="facebook">Facebook Lead Ad</option>
                        </select>
                    </div>
                    <div className="mb-5 w-1/3">
                        <label htmlFor="destinations" className="block text-lg font-medium text-gray-900">Choose Data Destination</label>
                        <select id="destinations" name="destination" onChange={onChangeHandler} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value=""></option>
                            <option value="acs">Adobe Campaign</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mx-auto">
                <OxButton value="Create Integration" onClickHandler={onButtonClickHandler} />
            </div>
        </div>
    );
}
export default NewIntegrationForm;
